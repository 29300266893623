export const commands = {
  clear: "clear",
  help:
    '<br />Supported commands: <span class="code">about</span>, <span class="code">clear</span>, <span class="code">contact</span>, ' +
    '<span class="code">education</span>, <span class="code">experience</span>, <span class="code">info</span>, <span class="code">resume</span>, <span class="code">skills</span>.<br /><br />',
  about:
    "<br />Hello! My name is Eldin Kajdic and I am a full stack developer from Sweden. <br/><br/>" +
    "I am a results-oriented, passionate and flexible developer with several years " +
    "of experience in full stack development and have been responsible for planning and building applications used by millions of users." +
    "<br><br>My goal is to always stay up-to-date by continuously investigating new technologies.<br />",
  skills:
    '<br /><span class="code">Programming languages:</span> ' +
    "C#, Java, JavaScript, PHP, Python." +
    '<br /><span class="code">Technologies:</span> ' +
    "Docker, Git, Jenkins, Octopus Deploy, Redux, REST, SQL, Team City, Testing.<br />" +
    '<span class="code">Frameworks:</span> ' +
    ".NET, AngularJS, Angular, ASP, Ionic, React.js, React Native, Spring, Vue.js." +
    '<br /><span class="code">Languages:</span> ' +
    "Bosnian, Croatian, English, German, Swedish." +
    "<br /><br />For more, please see my <a href='https://www.linkedin.com/in/eldin-kajdic/?locale=en_US' target='_blank' class='success link'>LinkedIn</a>.<br />",
  education:
    "<br />I have a bachelor's degree in Software Engineering from University West, Trollhättan, Sweden. The main part of the education consisted of app development, software architecture, software development, web development and databases." +
    " The other part of the education had a deeper focus on management, enterprise resource planning as well as process mapping.<br />",
  resume:
    "<br />View my resume here: <span class='success pointer openResumeButton'>Open resume</span>" +
    "<br />Download my resume here: <a href='./files/Resume.pdf' class='success link' download>Resume.pdf</a><br />",
  resumeMobile:
    "<br />View my resume here: <a href='./files/Resume.pdf' class='success link openResumeButtonMobile' download>Resume.pdf</a><br />",
  experience:
    "<div class='terminal-border'>" +
    "<br /><span class='code'>Experience:</span>" +
    "<br /><br />" +
    "<b>Future Eyewear Group (May 2017 - Apr 2018)</b>" +
    "<br /> Full Stack Developer at Future Eyewear Group in Kungälv, Sweden." +
    "<br /><br /><span class='code'>Key achievements: </span>" +
    "<br /> - Responsible for building a parcel scan-app that terminal workers used for inventory management" +
    "<br /> - Introduced a new system for terminal workers that cut the time spent on finding storage locations by almost 50%" +
    "<br /><br /><span class='code'>Technologies: </span>" +
    ".NET, Angular, C#, JavaScript, jQuery, REST API, UWP, WinForms, Xamarin." +
    "<br />" +
    "</div>" +
    "<br />" +
    "<div class='terminal-border'>" +
    "<br />" +
    "<span class='code'>Experience:</span>" +
    "<br /><br />" +
    "<b>DB Schenker (Apr 2018 - Sep 2019)</b>" +
    "<br />Full Stack Developer in a SCRUM team for DB Schenker in Gothenburg, Sweden." +
    "<br /><br /><span class='code'>Key achievements: </span>" +
    "<br /> - Responsible for development of parcel-tracking as well as delivery selection systems used by hundreds of thousands of users daily" +
    "<br /> - Responsible for development of mobile app used terminal workers" +
    "<br /> - Optimized legacy systems and cut loading speed averages from 11 seconds to 0.3 seconds" +
    "<br /><br /><span class='code'>Technologies: </span>" +
    "AngularJS, Angular2, Angular Material, Ionic, JMS, MSSQL, REST API, SQL Server, TSQL, VSTS, C# and .NET." +
    " DevOps: Git, TeamCity, Octopus Deploy, Azure DevOps." +
    "</div>" +
    "<br />" +
    "<div class='terminal-border'>" +
    "<br />" +
    "<span class='code'>Experience:</span>" +
    "<br /><br />" +
    "<b>Swedish Police Authority (Sep 2019 - Dec 2020)</b><br />" +
    "Full Stack Developer in a SCRUM team for the Swedish Police Authority in Gothenburg, Sweden." +
    "<br /><br /><span class='code'>Key achievements: </span>" +
    "<br /> - Responsible for development of systems used by millions of Swedish citizens" +
    "<br /> - Head of development for mobile app used by thousands of police officers and security guards every day" +
    "<br /><br /><span class='code'>Technologies: </span>" +
    "Angular2, CI/CD, Cucumber Testing, Docker, Git, Ionic, Jasmine, Java, Jenkins," +
    "JIRA, Karma, Kubernetes, Linux, Microservices, NgRx, OpenShift, React, Redux, REST API," +
    "RxJS, Selenium, Spring, Spring Boot, SQL Server, Vue, TDD, Thymeleaf.<br />" +
    "</div>" +
    "<br />" +
    "<div class='terminal-border'>" +
    "<br />" +
    "<span class='code'>Experience:</span>" +
    "<br /><br />" +
    "<b>FIFA (Dec 2020 - Nov 2021)</b><br />" +
    "Full Stack Developer in a SCRUM team for FIFA in Zürich, Switzerland." +
    "<br /><br /><span class='code'>Technologies: </span>" +
    "Angular2, CI/CD, .NET. C#" +
    "SQL Server, Vue.<br />" +
    "</div>" +
    "<br />" +
    "<div class='terminal-border'>" +
    "<br />" +
    "<span class='code'>Experience:</span>" +
    "<br /><br />" +
    "<b>FIFA (Dec 2020 - Nov 2021)</b><br />" +
    "Senior Engineering Manager for FIFA in Zürich, Switzerland." +
    "<br /><br /><span class='code'>Key achievements: </span>" +
    "<br /> - Managing a team consisting of 10 developers, 2 Business Analysts and 5 testers." +
    "<br /> - Responsible for FIFA's main event application during FIFA World Cup 2022 and FIFA Women's World Cup 2023." +
    "</div>" ,
  info:
    "<br />This page was built using ReactJS version 16.13.1. The total development time spent was 19 hours 45 minutes 20 seconds. <br />",
  contact:
    "<br />You can contact me by sending me a message on LinkedIn: <a href='https://www.linkedin.com/in/eldin-kajdic/?locale=en_US' target='_blank' class='success link'>Eldin Kajdic</a><br />",
};
