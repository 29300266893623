import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import PuffLoader from "react-spinners/PuffLoader";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "./Resume.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function Resume({ onPdfLoaded }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [displayNavigation, setDisplayNavigation] = useState(false);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
    setDisplayNavigation(true);
    onPdfLoaded();
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function renderLoader() {
    return <PuffLoader size={50} color={"#1178ff"} />;
  }

  return (
    <div id="resume">
      <div className="pdf-container">
        <Document
          file="./files/Resume.pdf"
          onLoadSuccess={onDocumentLoadSuccess}
          className="resume"
          externalLinkTarget="_blank"
          loading={renderLoader()}
        >
          <Page className="page" pageNumber={pageNumber} />
        </Document>
      </div>
      {displayNavigation && (
        <div className="button-container">
          <button
            type="button"
            disabled={pageNumber <= 1}
            onClick={previousPage}
            alt="Previous"
            aria-label="Previous"
            className="pdf-btn"
          >
            <FaArrowLeft />
          </button>
          {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
          <button
            type="button"
            disabled={pageNumber >= numPages}
            onClick={nextPage}
            alt="Next"
            aria-label="Next"
            className="pdf-btn"
          >
            <FaArrowRight />
          </button>
        </div>
      )}
    </div>
  );
}

export default Resume;
