import React, { useState, useEffect } from "react";
import { commands } from "./commands";

import { isMobile } from "react-device-detect";
import "./Terminal.css";
import { FaTerminal } from "react-icons/fa";

function Terminal({ openModal, displayTerminal }) {
  const [cursor, setCursor] = useState(false);
  const [userStateInput, setUserStateInput] = useState("");
  const [commandlist, setCommandlist] = useState(commands);
  const [terminalDisplayed, setTerminalDisplayed] = useState(false);
  const [isInit, setIsInit] = useState(false);

  useEffect(() => {
    let element = document.getElementById("floating-icon");
    if (element) {
      if (!isInit) {
        setTimeout(() => {
          element.classList.add("init");
          setIsInit(true);
        }, 1000);
      } else {
        element.classList.add("is-init");
      }
    }
    if (terminalDisplayed && !isMobile) {
      setFocus();
    }
    let content = document.getElementById("content");
    if (content) {
      content.onclick = () => {
        if (terminalDisplayed) {
          toggleTerminal();
        }
      };
    }
  });

  const setFocus = () => {
    let input = document.getElementById("terminalInput");
    input.focus();
    setCursor(true);
  };

  const displayCursor = () => {
    if (cursor && userStateInput.length === 0) {
      return true;
    }
    return false;
  };

  const handleChangeInput = (e) => {
    setUserStateInput(e.target.value);
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const toggleTerminal = () => {
    if (!terminalDisplayed) {
      setTerminalDisplayed(true);
      setTimeout(() => {
        const element = document.getElementById("terminal");
        element.classList.add("init");
        toggleScroll("OFF");
      }, 100);
    } else {
      const element = document.getElementById("terminal");
      element.classList.add("hide");
      element.classList.remove("init");
      toggleScroll("ON");
      setTimeout(() => {
        setTerminalDisplayed(false);
      }, 250);
    }
  };

  const toggleScroll = (val) => {
    const body = document.body;
    if (val === "ON") {
      body.style.height = "auto";
      body.style.overflowY = "visible";
    } else {
      body.style.height = "100vh";
      body.style.overflowY = "hidden";
    }
  };

  const destroyTerminal = () => {
    displayTerminal(false);
  };

  const expandTerminal = () => {
    let elements = document.getElementsByClassName("expandable");
    let isExpanded = false;
    let terminalWindow = document.getElementById("terminalWindow");
    for (let el of elements) {
      el.classList.toggle("expand");
      if (el.classList.contains("expand")) {
        isExpanded = true;
      }
    }
    if (isMobile) {
      if (isExpanded) {
        setTimeout(() => {
          terminalWindow.style.height = window.innerHeight + "px";
        }, 100);
      } else {
        terminalWindow.style.height = "50vh";
      }
    }
  };

  const executeTerminal = (e) => {
    let keycode = e.keyCode;
    var valid =
      keycode === 8 ||
      keycode === 46 ||
      (keycode > 47 && keycode < 58) ||
      keycode === 32 ||
      keycode === 13 ||
      (keycode > 64 && keycode < 91) ||
      (keycode > 95 && keycode < 112) ||
      (keycode > 185 && keycode < 193) ||
      (keycode > 218 && keycode < 223);

    if (!valid) return;
    if (e.key === "Enter") {
      let input = userStateInput.toLowerCase().trim();
      let userInput = document.getElementById("userInput");
      let inputKeyboard = document.getElementById("terminalInput");
      let terminalOutput = document.getElementById("terminalOutput");
      let output;

      if (input.length === 0) {
        return;
      }
      output = `<div class="terminal-line"><span class="success">➜</span> <span class="directory">~</span> ${input}</div>`;
      if (!commands.hasOwnProperty(input)) {
        if (input.includes("<") || input.includes(">")) {
          output += `<br /><div class="terminal-line">Nice try! You thought I didn't think about that?</div><br />`;
        } else {
          output += `<br /><div class="terminal-line">no such command: ${input}. Type <span class="code">'help'</span> to see a list of commands.</div><br />`;
        }
      } else {
        if (input === "resume" && isMobile) {
          output += commands["resumeMobile"];
        } else {
          output += commands[input];
        }

        if (input !== "help" && input !== "clear") {
          let list = { ...commandlist };
          delete list[input];
          setCommandlist(list);

          let unusedCommands = "<br /><div class='terminal-help-border'>";
          let commands = "";

          Object.keys(list)
            .sort()
            .forEach((element, index) => {
              if (
                element === "help" ||
                element === "clear" ||
                element === "resumeMobile"
              )
                return;
              commands +=
                `<span class="code">${element}</span>` +
                (index !== Object.keys(list).length - 1 ? ", " : ".");
            });

          if (commands.length > 0) {
            unusedCommands += "<br />Unused commands: ";
            unusedCommands += commands;
          }

          unusedCommands +=
            '<br />Type <span class="code">help</span> to view a list of available commands.<br /><br />';
          unusedCommands += "</div><br />";

          output += unusedCommands;
        }
      }

      if (commands[input] === "clear") {
        terminalOutput.innerHTML =
          '<div class="terminal-line">' +
          '<span class="help-msg">' +
          " Welcome!" +
          "<br />" +
          'Type <span class="code">about</span>, ' +
          '<span class="code">contact</span>, ' +
          '<span class="code">education</span>, ' +
          '<span class="code">experience</span>, ' +
          '<span class="code">info</span>, ' +
          '<span class="code">resume</span> or ' +
          '<span class="code">skills</span> to get started.' +
          "<br />" +
          "</span>" +
          "</div>";
      } else {
        terminalOutput.innerHTML = `${terminalOutput.innerHTML}<div class="terminal-line">${output}</div>`;
      }

      if (input === "resume" && !isMobile) {
        let resumeButtons = [
          ...document.getElementsByClassName("openResumeButton"),
        ];
        resumeButtons.forEach((el) => {
          el.addEventListener("click", openModal);
        });
      } else if (input === "resume" && isMobile) {
        let resumeButtons = [
          ...document.getElementsByClassName("openResumeButtonMobile"),
        ];
        resumeButtons.forEach((el) => {
          el.addEventListener("click", stopPropagation);
        });
      }

      terminalOutput.scrollTop = terminalOutput.scrollHeight;
      setUserStateInput("");
      userInput.innerHTML = "";
      inputKeyboard.value = "";
      if (isMobile) {
        inputKeyboard.blur();
      }
    } else if (e.keyCode === 8 || e.keyCode === 46) {
      let userInput = document.getElementById("userInput");
      userInput.innerHTML = userInput.innerHTML.slice(
        0,
        userInput.innerHTML.length - 1
      );
    } else {
      let userInput = document.getElementById("userInput");
      userInput.innerHTML += e.key;
    }
  };

  return (
    <div>
      {terminalDisplayed ? (
        <div id="terminal" className="terminal-popup expandable">
          <div className="column-child terminal shadow">
            <div
              className="terminal-bar dark-mode expandable"
              id="terminalLine"
            >
              <button
                alt="Close terminal"
                aria-label="Close terminal"
                className="icon-btn close-button"
                onClick={destroyTerminal}
              ></button>
              <button
                alt="Minimize terminal"
                aria-label="Minimize terminal"
                className="icon-btn minimize-button"
                onClick={toggleTerminal}
              ></button>
              <button
                alt="Maximize terminal"
                aria-label="Maximize terminal"
                className="icon-btn maximize-button"
                onClick={expandTerminal}
              ></button>
              <div className="terminal-bar-text dark-mode-text">
                Eldin Kajdic
              </div>
            </div>
            <div
              className="terminal-window primary-bg expandable"
              id="terminalWindow"
              onClick={setFocus}
            >
              <div className="terminal-output expandable" id="terminalOutput">
                <div className="terminal-line">
                  <span className="help-msg">
                    Welcome!
                    <br />
                    Type <span className="code">about</span>,{" "}
                    <span className="code">contact</span>,{" "}
                    <span className="code">education</span>,{" "}
                    <span className="code">experience</span>,{" "}
                    <span className="code">info</span>,{" "}
                    <span className="code">resume</span> or{" "}
                    <span className="code">skills</span> to get started.
                    <br />
                  </span>
                </div>
              </div>
              <div className="terminal-line expandable">
                <span className="success">➜</span>{" "}
                <span className="directory">~</span>{" "}
                {displayCursor() && (
                  <span className="cursor">
                    <i></i>
                  </span>
                )}
                <span className="user-input" id="userInput"></span>
                <input
                  type="text"
                  id="terminalInput"
                  className="terminal-keyboard"
                  autoCapitalize="off"
                  spellCheck={false}
                  onBlur={() => setCursor(false)}
                  onChange={handleChangeInput}
                  onKeyDown={executeTerminal}
                  autoComplete="off"
                  tabIndex="1"
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="floating-icon"
          id="floating-icon"
          onClick={toggleTerminal}
        >
          <FaTerminal />
        </div>
      )}
    </div>
  );
}

export default Terminal;
