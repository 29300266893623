import React, { useState } from "react";
import { FaTimes, FaSave } from "react-icons/fa";
import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import Header from "./components/Header/Header";
import Terminal from "./components/Terminal/Terminal";
import Resume from "./components/Resume/Resume";
import Modal from "react-modal";
import FileSaver from "file-saver";
import Project from "./components/Project/Project";
import { isMobile } from "react-device-detect";

function App() {
  Modal.setAppElement("body");

  const [modalIsOpen, setIsOpen] = useState(false);
  const [pdfLoaded, setPdfLoaded] = useState(false);
  const [displayTerminal, setDisplayTerminal] = useState(true);

  const modalStyle = {
    content: {
      top: "53%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: 0,
      overflow: "hidden",
      boxShadow: "0 30px 40px 0 rgba(16, 36, 94, 0.2)",
    },
  };

  const mobileprojects = [
    {
      id: 0,
      desc:
        "A mobile app built in React Native that shows weather information in a happy way.",
      gitUrl: "https://github.com/EldinKajdic/Weatheruu",
      title: "Weatheruu",
      technologies: ["CSS", "Javascript", "React"],
      src: "weather.mp4",
      link: "https://github.com/EldinKajdic/Weatheruu",
      year: "2019",
    },
    {
      id: 1,
      desc:
        "A delivery option selection system that allows DB Schenker customers to select a time of delivery.",
      gitUrl: null,
      title: "Delivery Selection",
      technologies: [".NET", "Angular", "Javascript", "MSSQL", "SASS"],
      src: "dbschenker.mp4",
      link: "https://myshipment.dbschenker.com/",
      year: "2018",
    },
  ];

  const webprojects = [
    {
      id: 0,
      desc:
        "Discover the best clubs, bars, beer gardens, cocktail bars, LGBTQ+ venues, and more at Nytez.",
      title: "Nytez",
      technologies: ["Vue", "Nuxt", "Javascript", "SASS"],
      src: "nytez-website.webp",
      link: "https://www.nytez.com",
      year: "2023",
    },
  ];

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setPdfLoaded(false);
  };

  const downloadFile = () => {
    FileSaver.saveAs("./files/Resume.pdf", "Resume.pdf");
  };

  const scrollToView = (id) => {
    const el = document.getElementById(id);
    el.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });

    if (isMobile) {
      window.scrollBy(0, -70);
    }
  };

  const onPdfLoaded = () => {
    setPdfLoaded(true);
  };

  const destroyTerminal = () => {
    setDisplayTerminal(false);
  };

  return (
    <div className="App" id="app">
      <div id="content">
        <Navbar scrollToView={scrollToView} openModal={openModal} />
        <Header />
        <Project mobileprojects={mobileprojects} webprojects={webprojects} />
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Resume Modal"
        style={modalStyle}
      >
        {pdfLoaded && (
          <div>
            <button
              alt="Close"
              aria-label="Close"
              onClick={closeModal}
              className="modal-btn"
            >
              <FaTimes />
            </button>
            <button
              alt="Download"
              aria-label="Download"
              onClick={downloadFile}
              className="modal-btn modal-btn-right"
            >
              <FaSave />
            </button>
          </div>
        )}
        <Resume onPdfLoaded={onPdfLoaded} />
      </Modal>
      {displayTerminal && (
        <Terminal openModal={openModal} displayTerminal={destroyTerminal} />
      )}
    </div>
  );
}

export default App;
