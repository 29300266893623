import React from "react";
import "./Header.css";
import { SocialIcon } from "react-social-icons";

function Header() {
  return (
    <div
      className="header-container"
      style={{ paddingLeft: 0, paddingRight: 0 }}
    >
      <div className="row header-page">
        <div className="particle" id="header-page"></div>
        <div className="header-content" id="background-circle">
          <div className="background-circle"></div>
          <canvas className="particles-js-canvas-el"></canvas>
        </div>
        <div className="col-12">
          <div className="page-title">
            <h1 className="animate-reveal animate-first">
              Hi. I'm Eldin Kajdic.
            </h1>
            <p className="animate-reveal animate-second">
              Passionate software developer
            </p>
            <ul className="social-list animate-reveal animate-third">
              <li>
                <SocialIcon
                  bgColor="#fff"
                  fgColor="#2867B2"
                  url="https://www.linkedin.com/in/eldin-kajdic/?locale=en_US"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              </li>
              <li>
                <SocialIcon
                  bgColor="#fff"
                  fgColor="#211F1F"
                  url="https://github.com/EldinKajdic"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              </li>
              <li>
                <SocialIcon
                  bgColor="#fff"
                  fgColor="#1DA1F2"
                  url="https://twitter.com/Eldin_Kajdic"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
