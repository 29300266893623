import React, { Suspense } from "react";
import PuffLoader from "react-spinners/PuffLoader";
import "./Project.css";

const Phone = React.lazy(() => import("./Phone/Phone"));
const Computer = React.lazy(() => import("./Computer/Computer"));

function Project({ mobileprojects, webprojects }) {
  return (
    <div className="project-container" id="portfolio">
      <Suspense
        fallback={
          <div className="lazy-loader">
            <PuffLoader size={50} color={"#1178ff"} />
          </div>
        }
      >
        <h2 className="section-subheader">Portfolio</h2>
        <h3 className="section-information">Web applications</h3>
        <Computer projects={webprojects} />
        <h3 className="section-information">Mobile applications</h3>
        <Phone projects={mobileprojects} />
      </Suspense>
    </div>
  );
}

export default Project;
