import React, { useEffect } from "react";
import FileSaver from "file-saver";
import { isMobile } from "react-device-detect";
import "./Navbar.css";

function Navbar({ scrollToView, openModal }) {
  useEffect(() => {
    animateNavbar();
  });

  const animateNavbar = () => {
    let nav = document.getElementById("nav");
    let navlinks = document.getElementById("navlinks");
    let navBtn = document.getElementById("navBtn");
    let navigationDiv = document.getElementById("navigation-div");
    let floatingIcon = document.getElementById("floating-icon");
    let scrollLimit = isMobile ? 50 : 500;

    if (isMobile) {
      floatingIcon.classList.add("mobile");
    }

    document.addEventListener("scroll", (e) => {
      var scrolled =
        document.body.scrollTop || document.scrollingElement.scrollTop;
      if (scrolled >= scrollLimit) {
        if (!nav.classList.contains("affix")) {
          nav.classList.add("affix");
          navlinks.classList.add("affix-border");
          navBtn.classList.add("nav-btn-sm");
          if (isMobile) {
            floatingIcon.classList.add("mobile-scrolled");
          }
        }
      } else {
        if (nav.classList.contains("affix")) {
          nav.classList.remove("affix");
          navlinks.classList.remove("affix-border");
          navBtn.classList.remove("nav-btn-sm");
          if (isMobile) {
            floatingIcon.classList.remove("mobile-scrolled");
          }
        }
      }
    });

    window.onclick = (e) => {
      if (
        e.target.id !== "navBtn" &&
        e.target.tagName !== "I" &&
        navigationDiv.classList.contains("show_list")
      ) {
        navBtn.click();
      }
    };
  };

  const onNavigationClicked = (e) => {
    let navBtn = document.getElementById("navBtn");
    let navigationDiv = document.getElementById("navigation-div");

    navBtn.classList.toggle("active");
    navigationDiv.classList.toggle("show_list");
  };

  const displayResume = () => {
    if (isMobile) {
      FileSaver.saveAs("./files/Resume.pdf", "Resume.pdf");
    } else {
      openModal();
    }
  };
  return (
    <nav className="nav" id="nav">
      <div className="container">
        <div className="logo" onClick={() => scrollToView("app")}>
          <img
            src={require("../../assets/images/logos/logo.png")}
            className="logo-img"
            alt="Eldin Kajdic"
          />
        </div>
        <div id="navigation-div" className="main_list">
          <ul className="navlinks" id="navlinks">
            <li>
              <p onClick={() => scrollToView("portfolio")}>Portfolio</p>
            </li>
            <li>
              <p onClick={displayResume}>Resume</p>
            </li>
          </ul>
        </div>
        <span
          id="navBtn"
          className="navigation-btn"
          onClick={onNavigationClicked}
        >
          <i></i>
          <i></i>
          <i></i>
        </span>
      </div>
    </nav>
  );
}

export default Navbar;
